<template>
  <div class="container">
    <div id="pageCenter">
      <div class="display-1">Page not found</div>
      <div class="text-center mt-4">
          <v-btn outlined small
          @click="$router.go(-1)">Go back</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
#pageCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
